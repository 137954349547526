import API from '../api';

export default {
  register(options = {
    override: {},
  }) {
    return new Promise(((resolve, reject) => {
      API.requestGet({
        url: 'api/common/geetest/register'
      }, false)
        .then(data => {
          if (!data || !data.gt) {
            reject('初始化验证码失败');
            return ;
          }
          initGeetest({
            gt: data.gt,
            challenge: data.challenge,
            new_captcha: data.new_captcha, // 用于宕机时表示是新验证码的宕机
            offline: !data.success, // 表示用户后台检测极验服务器是否宕机，一般不需要关注
            product: "bind",
            width: "300px",
            ... (options.override || {}),
          }, (captchaObj) => {
            resolve(captchaObj);
          })
        })
    }));
  }
}
